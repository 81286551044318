<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy Vosita Hujjati registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="3" class="pl-0 py-0">
                <v-text-field
                  :error-messages="reg_nomerErrors"
                  v-model="reg_nomer"
                  label="Registratsiya raqami"
                  outlined
                  dense
                  :success="reg_nomer !== ''"
                  @input="$v.reg_nomer.$touch()"
                  @blur="$v.reg_nomer.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pl-0 py-0">
                <v-text-field
                  v-model="oper_date"
                  placeholder="дд-мм-гггг"
                  label="Sana"
                  outlined
                  dense
                  v-mask="'##-##-####'"
                  :success="oper_date !== ''"
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="responsibleErrors"
                  :items="allStuffs"
                  v-model="responsible"
                  v-debounce:400ms="myStaff"
                  :success="
                    responsible !== '' &&
                    responsible !== null &&
                    responsible !== undefined
                  "
                  item-text="full_name"
                  item-value="id"
                  label="Masul shaxs"
                  outlined
                  dense
                  @input="$v.responsible.$touch()"
                  @blur="$v.responsible.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="payment_sourceErrors"
                  :items="paymentSource"
                  v-model="payment_source"
                  dense
                  :success="
                    payment_source !== '' &&
                    payment_source !== null &&
                    payment_source !== undefined
                  "
                  item-text="name"
                  item-value="id"
                  label="To'lov turi"
                  outlined
                  @input="$v.payment_source.$touch()"
                  @blur="$v.payment_source.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pl-0 py-0" v-if="show == 'EX'"> </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="contragentErrors"
                  :items="contragentsList"
                  v-model="contragent"
                  @change="getContracts"
                  v-debounce:400ms="myFn"
                  item-text="full_name"
                  dense
                  :success="
                    contragent !== '' &&
                    contragent !== null &&
                    contragent !== undefined
                  "
                  item-value="id"
                  label="Kontagent"
                  outlined
                  @input="$v.contragent.$touch()"
                  @blur="$v.contragent.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" class="pl-0 py-0 pb-2">
                <v-autocomplete
                  :error-messages="contragent_contractErrors"
                  :items="contragent_contractLists"
                  v-model="contragent_contract"
                  item-text="reg_number"
                  dense
                  :success="
                    contragent_contract !== '' &&
                    contragent_contract !== null &&
                    contragent_contract !== undefined
                  "
                  item-value="id"
                  label="Kontagent Shartnomasi"
                  outlined
                  @input="$v.contragent_contract.$touch()"
                  @blur="$v.contragent_contract.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <v-textarea
                  v-model="comment"
                  :success="comment !== '' && comment !== null"
                  name="input-7-1"
                  dense
                  label="Comment"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pl-0 pb-2">
                <b-tabs content-class="mt-3">
                  <b-tab
                    v-for="tab in allCategoryGroupNames"
                    :key="tab.id"
                    :title="tab.name"
                    v-show="
                      tab.code == '0001' ||
                      tab.code == '0002' ||
                      tab.code == '0005'
                    "
                    @click="getCategorData(tab)"
                  >
                    <v-row align="center">
                      <v-col class="d-flex" cols="12" sm="12">
                        <v-select
                          v-model="products_services.import_category_id"
                          :items="getCategoryGroupData"
                          item-text="name"
                          item-value="id"
                          label="Nomenklatira"
                          outlined
                          class="mx-1 w-30"
                          return-object
                          dense
                        ></v-select>
                        <v-select
                          v-if="tab.code == '0001'"
                          v-model="product_condition"
                          :items="conditions"
                          item-text="name"
                          item-value="id"
                          label="Holati"
                          outlined
                          class="mx-1"
                          dense
                          style="width: 15%"
                        ></v-select>
                        <v-text-field
                          v-if="
                            products_services.import_category_id !== undefined
                          "
                          :value="
                            products_services.import_category_id
                              .measurement_name
                          "
                          disabled
                          label="o'lchov"
                          outlined
                          class="mx-1"
                          style="width: 8%"
                          dense
                        >
                          {{
                            products_services.import_category_id
                              .measurement_name
                          }}</v-text-field
                        >
                        <v-text-field
                          v-model="amount"
                          label="Soni"
                          outlined
                          :class="text"
                          dense
                          class="mx-1"
                          style="width: 8%"
                        ></v-text-field>
                        <v-text-field
                          v-model="price"
                          label="Narxi"
                          outlined
                          v-currency="options"
                          dense
                          class="mx-1"
                        ></v-text-field>
                        <v-text-field
                          v-model="total"
                          label="Summa"
                          v-currency="options"
                          outlined
                          dense
                          class="mx-1"
                        ></v-text-field>
                        <v-text-field
                          v-model="nds"
                          label="nds"
                          placeholder="0"
                          outlined
                          dense
                          class="mx-1"
                        ></v-text-field>
                        <!-- <v-text-field
                          :value="((nds1 * price1) / 100 || 0) | mask"
                          label="Nds(dona)"
                          outlined
                          disabled
                          dense
                          class="mx-1"
                        ></v-text-field> -->
                        <v-text-field
                          v-model="ndssumma"
                          label="Nds summa"
                          v-currency="options"
                          outlined
                          dense
                          class="mx-1"
                        ></v-text-field>
                        <v-text-field
                          :value="withNds | mask"
                          label="Summa(NDS bilan)"
                          outlined
                          disabled
                          dense
                          class="mx-1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </b-tab>
                </b-tabs>
                <div>
                  <v-row justify="end" class="text-right">
                    <v-col>
                      <v-btn @click="remove" color="error" class="mx-1" dense
                        >O'chirish</v-btn
                      >
                      <v-btn @click="add" dense color="success">Qo'shish</v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-data-table
                  v-if="items.length !== 0"
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.price`]="{ item }">
                    {{ item.price | mask }}
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    {{ item.total | mask }}
                  </template>
                  <template v-slot:[`item.nds`]="{ item }">
                    {{ item.nds | mask }}
                  </template>
                  <template v-slot:[`item.nds_summa`]="{ item }">
                    {{ item.nds_summa | mask }}
                  </template>
                  <template v-slot:[`item.total_withnds_summa`]="{ item }">
                    {{ item.total_withnds_summa | mask }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
                :disabled="mainassetLoading"
              >
                <i v-if="mainassetLoading" class="el-icon-loading"></i> Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { CurrencyDirective } from 'vue-currency-input'
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'

export default {
  directives: { currency: CurrencyDirective },
  data() {
    return {
      mainassetLoading: false,
      comment: null,
      responsible: '',
      show: false,
      value: null,
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false,
      placement_expenditure: null,
      headers: [
        {
          text: 'Nomenklatira',
          value: 'import_category_id.name'
        },
        { text: "O'lchov", value: 'import_category_id.measurement_name' },
        { text: 'soni', value: 'amount' },
        { text: 'Narxi', value: 'price' },
        { text: 'Summa', value: 'total' },
        { text: 'Nds', value: 'nds' },
        { text: 'NDS summa', value: 'nds_summa' },
        { text: 'Summa (NDS bilan)', value: 'total_withnds_summa' }
      ],
      categoryId: null,
      tab: '',
      menu2: false,
      products_services: {},
      oper_date: '',
      reg_nomer: '',
      contragent: '',
      contragentsList: [],
      contragent_contract: '',
      contragent_contractLists: [],
      items: [],
      items1: [],
      import_group_id: '',
      amount: '',
      price: '',
      price1: 0,
      total: '',
      total1: 0,
      nds: '',
      nds1: '',
      withNds1: '0',
      withNds2: 0,
      ndssumma: '',
      ndssumma1: 0,
      totalSumma: 0,
      totalNdsSumma: 0,
      totalWithNdsSumma: 0,
      product_condition: '',
      payment_source: ''
    }
  },
  validations: {
    oper_date: {
      required
    },
    payment_source: {
      required
    },
    reg_nomer: {
      required
    },
    contragent: {
      required
    },
    contragent_contract: {
      required
    },
    responsible: {
      required
    }
  },
  created() {
    // this.products_services.nds = "0";
    this.$store.dispatch('getAllSkladList')
    this.$store.dispatch('getPaymentSource')
    this.$store.dispatch('getAllStaff')
    this.$store.dispatch('getProductCondition')
    this.getCategoryGroupNames()
    this.getMeasurements()
  },
  watch: {
    contragent: function () {
      this.contragent_contract = ''
      this.$v.contragent_contract.$reset()
    },
    amount: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(!val)) {
        val = 0
      }
      this.total = (val * this.price1).toLocaleString('en-GB')
    },
    price: function (val) {
      if (!val) {
        val = 0
      }
      this.price1 = this.toInt(val)
      this.total = (this.amount * this.price1).toLocaleString('en-GB')
    },
    total: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(!val)) {
        val = 0
      }
      this.total1 = this.toInt(val)
      this.ndssumma = ((this.nds1 * this.total1) / 100).toLocaleString('en-GB')
    },
    nds: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(!val)) val = 0
      this.nds1 = val
      this.ndssumma = ((this.nds1 * this.total1) / 100).toLocaleString('en-GB')
    },
    ndssumma: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(!val)) {
        val = 0
      }
      this.ndssumma1 = this.toInt(val)
    }
  },
  computed: {
    paymentSource() {
      const data = this.$store.state.requests.paymentSource
      return data
    },
    allCategoryGroupNames() {
      const data = this.$store.state.requests.categoryGroupNames.filter(
        (x) => x.code == '0001' || x.code == '0002' || x.code == '0005'
      )
      return data
    },
    allStuffs() {
      return this.$store.state.requests.allStaff
    },
    conditions() {
      const data = this.$store.state.requests.productCondition
      if (data != 0 && data[0]) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.product_condition = data[0].id
      }
      return data
    },
    withNds() {
      return this.total1 + this.ndssumma1
    },
    ...mapGetters(['getCategoryGroupData']),
    options() {
      return {
        locale: 'zh-ZH',
        currency: [null],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    getMeasurement() {
      return this.$store.state.requests.measurements
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors

      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    payment_sourceErrors() {
      const errors = []
      if (!this.$v.payment_source.$dirty) return errors

      !this.$v.payment_source.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contragentErrors() {
      const errors = []
      if (!this.$v.contragent.$dirty) return errors

      !this.$v.contragent.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    responsibleErrors() {
      const errors = []
      if (!this.$v.responsible.$dirty) return errors

      !this.$v.responsible.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    contragent_contractErrors() {
      const errors = []
      if (!this.$v.contragent_contract.$dirty) return errors

      !this.$v.contragent_contract.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_nomerErrors() {
      const errors = []
      if (!this.$v.reg_nomer.$dirty) return errors

      !this.$v.reg_nomer.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('en-GB')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Asosiy Vosita Hujjati registratsiyasi' }
    ])
  },
  methods: {
    toInt(val) {
      if (typeof val == 'string') {
        return parseInt(val.replace(/[ ,]/g, ''), 10)
      } else return val
    },
    ...mapActions(['getCategoryGroupNames', 'getMeasurements']),
    remove() {
      if (this.items.length > 2) {
        this.items.reverse()
        this.totalNdsSumma -= this.items[this.items.length - 1].nds_summa
        this.totalSumma -= this.items[this.items.length - 1].total
        this.totalWithNdsSumma -=
          this.items[this.items.length - 1].total_withnds_summa
        this.items[0].nds_summa = this.totalNdsSumma
        this.items[0].total = this.totalSumma
        this.items[0].total_withnds_summa = this.totalWithNdsSumma
        this.items.splice(-1, 1)
        this.items.reverse()
      } else if (this.items.length == 2) {
        this.items.splice(-1, 1)
        this.items.splice(-1, 1)
        this.totalNdsSumma = 0
        this.totalSumma = 0
        this.totalWithNdsSumma = 0
      }
    },
    add() {
      if (this.nds1 == '') {
        this.nds1 = 0
      }
      this.nds1 = parseInt(this.nds1, 10)
      this.products_services.amount = parseInt(this.amount, 10)
      this.products_services.price = this.price1
      this.products_services.total = this.total1
      this.products_services.nds = this.nds1
      this.products_services.nds_per_item = (this.nds1 * this.price1) / 100
      this.products_services.nds_summa = this.ndssumma1
      this.products_services.total_withnds_summa = this.withNds
      if (
        this.products_services.amount !== 0 &&
        this.products_services.price !== 0 &&
        this.products_services.import_category_id !== undefined
      ) {
        this.totalSumma += this.products_services.total
        this.totalNdsSumma += this.products_services.nds_summa
        this.totalWithNdsSumma += this.products_services.total_withnds_summa
        this.items.reverse()
        if (this.import_group_id == '') {
          this.import_group_id = this.allCategoryGroupNames[0].id
        }
        this.products_services.import_group_id = this.import_group_id
        this.products_services.product_condition_id = this.product_condition
        if (this.items.length == 0) {
          const data = {
            amount: '',
            import_category_id: {},
            import_group_id: '',
            nds: '',
            nds_per_item: '',
            nds_summa: '',
            price: '',
            total: '',
            total_withnds_summa: 0
          }
          this.items.push(data)
        }
        this.items.push(this.products_services)
        this.items.reverse()
        this.items[this.items.length - 1].import_category_id.name =
          'Umumiy summa:'
        this.items[this.items.length - 1].nds_summa = this.totalNdsSumma
        this.items[this.items.length - 1].total = this.totalSumma
        this.items[this.items.length - 1].total_withnds_summa =
          this.totalWithNdsSumma
        this.price1 = 0
        this.amount1 = 0
        this.total1 = 0
        this.ndssumma1 = 0
        this.nds1 = 0
        this.amount = ''
        this.price = ''
        this.total = ''
        this.nds = ''
        this.ndssumma = ''
        this.products_services = {}
      } else {
        Swal.fire({
          title: 'Xato malumot toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    },
    getCategorData(value) {
      if (value.code == '0001' && this.conditions && this.conditions.length) {
        this.product_condition = this.conditions[0].id
      } else {
        this.product_condition = null
      }
      this.import_group_id = value.id
      this.price1 = 0
      this.amount1 = 0
      this.total1 = 0
      this.ndssumma1 = 0
      this.nds1 = 0
      this.amount = ''
      this.price = ''
      this.total = ''
      this.nds = ''
      this.ndssumma = ''
      this.products_services = {}
      this.$store.dispatch('getCategoryGroupData', value.id)
    },
    myFn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('counteragentsSearch', data).then((res) => {
        this.contragentsList = []
        this.contragentsList = [...res]
      })
    },
    getContracts() {
      if (this.contragent !== null) {
        this.$store
          .dispatch('counteragentContracts', this.contragent)
          .then((res) => {
            this.contragent_contractLists = []
            this.contragent_contractLists = [...res]
          })
      }
    },
    myStaff(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('getStaffList', data)
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          document: {
            comment: this.comment,
            payment_source: this.payment_source,
            responsible: this.responsible.id,
            oper_date: this.oper_date.split('-').reverse().join('-'),
            reg_number: this.reg_nomer,
            contragent: this.contragent,
            contragent_contract: this.contragent_contract
          },
          main_assets_elements: []
        }
        if (this.items.length !== 0) {
          this.items.splice(-1, 1)
          this.items.forEach((x) => {
            x.import_category_id = x.import_category_id.id
            delete x.measurement_id
          })
          data.main_assets_elements = [...this.items]
          this.mainassetLoading = false
          this.$store
            .dispatch('mainAssetsCreate', data)
            .then(() => {
              this.mainassetLoading = false
            })
            .catch((err) => {
              console.log(err)
              this.mainassetLoading = false
            })
          this.items = []
        } else {
          Swal.fire({
            title: '',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
        this.$v.$reset()
      }
    }
  }
}
</script>
<style scoped>
.w-30 {
  width: 32%;
}
.w-20 {
  width: 20%;
}
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
.text {
  font-size: 10px !important;
}
</style>
